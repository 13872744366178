import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 140px 0 0 0;
  @media (max-width: 990px) {
    padding: 140px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 120px 0 0 0;
  }
  @media (max-width: 480px) {
    padding: 100px 0 0 0;
  }
`

export const ContentSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -70px;
  width: 100%;
  color: #fff;

  .content {
    padding: 40px 30px;
    width: 800px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 0px;
      margin-bottom: 24px;
    }

    ul {
      margin-bottom: 32px;
    }

    li {
      font-size: 14px;
      color: white;
      text-align: center;
      margin-bottom: 6px;
    }

    .terms {
      color: red;
      margin-bottom: 6px;
      font-size: 14px;
    }
  }
`

export const ButtonGroup = styled.div`
  .reusecore__button {
    text-transform: inherit;
    &:first-child {
      color: ${themeGet("colors.white", "#fff")};
      background-color: ${themeGet("colors.secondaryBotton", "#886638")};
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      &:hover {
        background-color: #fbfbf9;
        border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
        color: ${themeGet("colors.secondaryBotton", "#886638")};
      }
    }
  }
`

export default SectionWrapper

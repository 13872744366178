import Fade from "react-reveal/Fade"
import React from "react"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Text from "../../components/Text"
import SectionWrapper, {
  ButtonGroup,
  ContentSection,
} from "./blackFriday.style"

const BlackFriday = () => {
  return (
    <SectionWrapper>
      <Container width="1340px">
        <Fade up delay={200}>
          <ContentSection>
            <div className="content">
              <Text content="You cannot say Shikha doesn’t spoil her wonderful clients. So this year Shikha Claus is starting Christmas early with an EXCLUSIVE Black FriYAY offer of buy a £25 gift voucher and get a £25 gift voucher FREE." />
              <Text content="You can buy yours here but there is only ONE allowed per person and limited available so be sure not to miss out." />
              <Text content="Terms and Conditions include:" className="terms" />
              <ul>
                <li>Voucher not valid on treatments below £150</li>
                <li>Voucher not valid on products</li>
                <li>One voucher allowed per person</li>
                <li>Cannot be used in conjunction with any other offer</li>
                <li>
                  Book through emailing{" "}
                  <a href="mailto: teagan@elegantclinic.co.uk">
                    teagan@elegantclinic.co.uk
                  </a>
                </li>
              </ul>
              <iframe
                src="https://www.robovoucher.com/buy/view/5fhh4x497x/"
                name="RoboVoucherFrame"
                id="RoboVoucherFrame"
                scrolling="auto"
                frameborder="0"
                marginheight="0px"
                marginwidth="0px"
                height="1300px"
                width="100%"
              />
            </div>
          </ContentSection>
        </Fade>
      </Container>
    </SectionWrapper>
  )
}

export default BlackFriday

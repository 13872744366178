import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import BlackFriday from "../containers/BlackFriday"
import ChangeYourLife from "../containers/ChangeYourLife"
import Container from "../components/UI/Container"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"

const BlackFriyay = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      elegantJson {
        blackFridayPage {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const ImageWrapper = styled.div`
    margin-top: 80px;
    @media (max-width: 990px) {
      margin-top: 80px;
    }
    @media (max-width: 767px) {
      margin-top: 56px;
    }
  `

  const url = location.href ? location.href : ""
  return (
    <>
      <SEO
        url={url}
        title="Black FriYAY..."
        shortDesc="Elegant Beauty Clinic"
        description=" Shikha Claus is starting Christmas early with an EXCLUSIVE Black FriYAY offer of buy a £25 gift voucher and get a £25 gift voucher FREE."
      />
      <Layout>
        <ContentWrapper>
          <Fade up delay={100}>
            <PageTitle title="Black FriYAY..." subtitle="Elegant Beauty" />
          </Fade>
          <Container width="1800px">
            <Fade up delay={200}>
              <ImageWrapper>
                <Image
                  fluid={
                    data.elegantJson.blackFridayPage.image.childImageSharp.fluid
                  }
                  alt="Video Preview Image"
                />
              </ImageWrapper>
            </Fade>
          </Container>
          <Fade up delay={300}>
            <BlackFriday />
          </Fade>
          <ChangeYourLife
            title="Ready to start your Elegant Beauty journey?"
            text="We’ll support you from initial consultation to reaching your final goal. Get in touch and book your first appointment today."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default BlackFriyay
